.login-wrapper {
  // border: 1px solid black;
  height: 100vh;

  .login-col-left {
    // border: 1px solid black;
    background-color: #223189;
    .img-wrapper {
      margin: auto;
      height: 34.813rem !important;
      width: 23.25rem;
      margin-top: 5rem;
    }
  }
  .login-col-right {
    // border: 1px solid black;
    background-color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .log-in-credential {
      // border: 1px solid black;
      height: 20rem;
      width: 30rem;
      .div-login-label {
        @include main-text();
      }
      .div__inputs__wrapp {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1.5rem 0rem;
        .div--email--input {
          width: 100%;
        }
        .div--password--input {
          width: 100%;
        }
        .login--btn {
          min-width: 100%;
          max-width: 100%;
          height: 3rem;
        }
      }
    }
    .login-wait-message {
      display: flex;
      flex-direction: column;
      .div--head--label {
        @include normal-text();
      }
      .div--helper--text {
        @include sub-text();
      }
    }
  }
}
