.slider__image__wrapper {
  width: 100%;
  height: 314px;
  border-radius: $border-radius12px;
margin-bottom: 2rem;
  img {
    width: 100%;
    height: 100%;
  border-radius: $border-radius12px;

  }
}
