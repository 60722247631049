.menu-home-wrapper{
    // border: 1px solid black;
    height: 100%;
    width:30rem;
    margin-left: 1.875rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    // justify-content: space-evenly;
    .select-container{
        .menu-home-label{
            margin-bottom: 1rem;
            color: #414141;
            font-size: 1rem;
            font-family: $manrope-font;
        }
    }

    .text-container{
        margin-top: 2rem;
        .menu-home-label{
            margin-bottom: 1.5rem;
            color: #414141;
            font-size: 1rem;
            font-family: $manrope-font;
        }
    }
    
    .save-btn-container{
        position: absolute;
        bottom: 2rem;
        align-self: flex-start;
    }

}