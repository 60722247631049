// app content main wrapper styles

.content-wrapper {
  width: calc(100% - 250px);
  transition: margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: auto;
  .main__content__wrapper {
    margin: 0 1.375rem;
  }
}
.grabbable {
  flex: unset !important;
  width: unset !important;
}
