.page-main-section {
  display: flex;
  flex-flow: row wrap;
  .page-default-section {
    display: flex;
    flex-direction: row;
    flex-basis: calc(100% / 4);
    margin-right: 0;
  }
}
