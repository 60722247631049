.side__slider__header__label__wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 8;
  background-color: $slider-header-bg;

  .side__slider__close {
    cursor: pointer;

    .bi-x {
      position: relative;
      top: 1px;
      font-size: 1.3rem;
      margin-right: 0.5rem;
      color: $moderate-blue;
    }
  }
  .standard-page--label {
    color: $moderate-blue;
    font-weight: $font-weight700;
  }
}
.seo-slider-inputfield-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.side__slider__panel__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
  z-index: 10;
  background-color: $white-color;
  box-shadow: 0px -1px 16px 2px rgba(97, 97, 97, 0.1),
    0px -1px 32px 2px rgba(97, 97, 97, 0.03);

  .side__slider__panel__btn_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    .delete__btn__wrapper {
      color: $strong-red;
      font-weight: $font-weight600;
      background-color: $white-color;
      border: 1px solid $strong-red;

      &:hover {
        background-color: $light-pale-red;
      }

      &::before {
        background-color: $light-pale-red;
      }
    }
  }
  .delete__btn__wrapper {
    color: $strong-red;
    font-weight: $font-weight600;
    background-color: $white-color;
    border: 1px solid $strong-red;

    &:hover {
      background-color: $light-pale-red;
    }

    &::before {
      background-color: $light-pale-red;
    }
  }
}
.height-64px {
  height: 64px;
}
.standard__slider__label {
  color: $dark-gray;
}
// .slider__parent__wrapper {
//   padding: 0 1rem 1rem 1rem;
//   margin: 1rem;
// }
