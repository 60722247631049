// style for switch property
.standard__switch__property__wrapper {
    position: fixed;
    box-sizing: border-box;
    inset: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    overflow: auto;
    z-index: 7890; /* second highest z index on the project*/
    .switch__property__content__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .div--head--label {
            @include main-text();
        }
        .div--helper--text {
            @include normal-text();
            font-weight: $font-weight400;
        }
    }
}