// Edit Home Styles
.home-main-container-wrapper {
  display: flex;
  flex-direction: column;

  .carousel-container {
    margin-top: 1.5rem;
    --bs-gutter-x: 0rem;
    background-color: $very-light-gray;
    border-radius: $border-radius8px;

    .home-page-slider-img {
      height: 25rem;
      border-radius: $border-radius8px;
      border: 1px solid $border-line;
    }

    .button-prev {
      @extend %small-btn;
      position: absolute;
      top: 40%;
      left: 1rem;
    }

    .button-next {
      @extend %small-btn;
      position: absolute;
      top: 40%;
      right: 1rem;
    }

    .carousel-root {
      padding: 0;
    }

    .edit-btn {
      @extend %small-btn;
      position: absolute;
      top: 12rem;
      right: 3rem;
    }
  }

  .home-add-sec-card {
    @include center-div-flex();

    .section-btn {
      width: 25%;
    }
  }
}

%small-btn {
  background-color: $white-text;
  height: 30px;
  width: 30px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #0f56b3;
  cursor: pointer;
}

.page-home-section-order {
  --bs-gutter-x: 0rem;
  border: 1px solid $border-line;
  background-color: $white-text;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  .section-card-wrapper {
    display: flex;
    justify-content: center;
  }

  // justify-content: space-around;
  .home-savebtn {
    width: 10%;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 3rem;
    margin-bottom: 1.5rem;
  }
}

// Set as Default Styles

.form-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;

  .form-check-label {
    color: #414141;
    font-family: $manrope-font;
    font-weight: $font-weight400;
    margin-right: 2rem;
  }

  .form-check-input {
    height: 1.375rem;
    width: 1.375rem;
    align-self: center;

    &:hover {
      cursor: pointer;
    }
  }
}

// Video Slider Page Styles

.vdo-slider-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .vdo-sldr-left {
    display: flex;
    flex-direction: column;
    flex: 1;

    .or {
      @include normal-text();
    }
  }

  .vdo-sldr-right {
    display: flex;
    flex: 1;

    .vdo-slider {
      border-radius: 0.5rem;
    }
  }
}