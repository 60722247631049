.switch{
    position: relative;
    display: inline-block;
    width: 10.375rem;
    height: 2.5rem;
    background-color:#C5DCFA;
    border: 1px soild red;
    border-radius: 0.875rem !important;
    box-shadow:  inset 0px 1px 2px rgba(97, 97, 97, 0.2), inset 0px 2px 4px rgba(97, 97, 97, 0.2) !important;
    cursor: pointer;
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #C5DCFA;        ;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 0.875rem;
        &:before {
            position: absolute;
            content: "";
            height: 2rem;
            width: 4.375rem;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 0.875rem;
          }
      }
      input:checked + .slider + .labels::after{
        // background-color: #fff;
        opacity: 1;
        color:#000
      }
      input:checked + .slider + .labels::before{
        // background-color: #fff;
        opacity: 1;
        color:#0F56B3;
      }
    //   input:focus + .slider +.labels::before{
    //     // box-shadow: 0 0 1px #2196F3;
    //     color:#000 ;
    //   }
      input:checked + .slider:before {
        -webkit-transform: translateX(5.625rem);
        -ms-transform: translateX(5.625rem);
        transform: translateX(5.625rem);
      }
      .labels{
        position: absolute;
        top: 8px;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1rem;
        font-family: sans-serif;
        transition: all 0.4s ease-in-out;
        &::after{
            content: attr(data-off);
            position: absolute;
            right: 0.938rem;
            color: #0F56B3;
            opacity: 1;
            text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
            transition: all 0.4s ease-in-out;
        }
        &::before{
            content: attr(data-on);
            position: absolute;
            left: 0.938rem;
            color:#000;
            opacity: 1;
            text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
            transition: all 0.4s ease-in-out;
        }
      }
      .switch input:checked~.labels::after {
        opacity: 1;
        color: #0F56B3;
      }
  
      .switch input:checked~ {
        opacity: 1;
        color: #000;
      }
          
}