.email__section {
  @include center-div-flex;

  .email__addbtn {
    width: 35px;
    height: 35px;
    text-align: center;
    padding-top: 0.2rem;
    margin-left: 1rem;
    margin-bottom: 1.2rem;
    background-color: $moderate-blue;
    border-radius: $border-radius50per;

    .bi-plus-lg {
      font-size: 20px;
      font-weight: $font-weight800;
      color: $white-color;
    }
  }
}

.category_list_wrapper {
  .list_label {
    @include sub-text();
  }

  .drag_list_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .slider__draggable {
      min-width: 280px;
      background-color: #fff;
      border-radius: 1rem;
      height: 4.813rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #616161;
      font-weight: $font-weight600;
      font-family: $manrope-font;
      box-shadow: 0px 1px 16px 2px rgba(97, 97, 97, 0.1),
        0px 16px 32px 2px rgba(97, 97, 97, 0.1);

      .left-section {
        display: flex;
        flex-direction: row;

        // gap: 1rem;
        .bi-grip-vertical {
          color: #223189;
          font-size: 1rem;
        }
      }

      .right-section {
        margin-right: 0.5rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .button {
          @extend %small-btn;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);

          .bi-trash {
            color: #e64467 !important;
          }
        }
      }
    }
  }


}