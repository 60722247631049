.input-check-add-butn-wrapper {
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 1rem;
}

.edit-hotel-service-card-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  // justify-content: space-between;

  .grabbable {
    min-width: calc(100%/5) !important;
    // border: 1px solid $border-line;
    background-color: $white-color;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 6px 10px -6px black;

    .selected_service_wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      .left-service-card {
        @include normal-text();

        .bi-grip-vertical {
          color: $moderate-blue;
        }
      }

      .right-service-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          // border: 1px solid $border-line;
          @include center-div-flex();
          box-shadow: 0 9px 10px -6px black;

          .bi-pencil-square {
            color: $moderate-blue
          }

          .bi-trash {
            color: $soft-red
          }

          &:hover {
            cursor: pointer !important;
          }
        }
      }
    }

    .services-section-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-color: $white-color;
      border-radius: 0.5rem;
      padding: 1rem;
    }
  }

}

.service_icon_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .service_icon {
    border: 1px solid $border-line;
    border-radius: 8px;
    padding: 10px;

    @include normal-text();
  }

  .selected {
    background-color: #ddd;
  }

  &:hover {
    cursor: pointer;
  }
}

.auto__width__card__container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
}

// .default_label {
//   @include center-div-flex;
// }
.auto__card__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.acco__input {
  margin: 2rem;
  // margin-top: -0.1875rem;
}