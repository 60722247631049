.wb-subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .breadcrumbLink,
  .wb-subheader-title {
    color: #223189;
    font-size: 1rem;
    font-weight: bold;
    font-family: $manrope-font;
  }

  .publish-sync-btn-wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    .wb-subheader-publish {
      background-color: #ffffff;
      border: 1px solid #c0c0c0;
      border-radius: 0.5rem;
      height: 2.7rem;
      width: 17rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: relative;

      .wb-subheader-publish-text {
        text-align: center;
        color: $moderate-blue;
        font-weight: bold;
        display: flex;
        flex-direction: row;

        .wb-subheader-publish-icon {
          // position: absolute;
          // top:0.55rem;
          // left:0.5rem;
          font-size: 1.4rem;
          margin-right: 0.8rem;
        }

        .publish {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .wb-subheader-rightsec {
    // width: 50%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: end;

    .header-footer-toggle {
      align-self: flex-end;

      .nav-tabs {
        width: fit-content;
        padding: 0.3rem;
        border-radius: $border-radius12px;
        background-color: $slider-header-bg;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          @include normal-text();
          min-width: 60px;
          color: $moderate-blue;
          font-weight: $font-weight600;
          text-align: center;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: $white-color;
          border-radius: $border-radius12px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }
}

.wb-divider {
  margin-top: 1rem;
  background-color: $table-border-color;
  height: 1px;
  width: 100%;
}