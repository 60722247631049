.dot-windmill {
  position: relative;
  top: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 5px 15px;
  animation: dotWindmill 2s infinite linear;
}

.dot-windmill::before,
.dot-windmill::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot-windmill::before {
  left: -8.66px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-windmill::after {
  left: 8.66px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

@keyframes dotWindmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}
