// list of standard varibles defined

// text colors
$bg-color: #f5f6f8;
$white-text: #ffffff;
$white-color: #ffffff;
$dark-black: #020202;
$dark-gray: #414141;
$dark-bright-gray: #818181;
$light-gray: #f8f9fa;
$very-light-gray: #efefef;
$bold-gray: #616161;
$lighter-gray: #a1a1a1;
$soft-gray: #e5e5e5;
$location-marker-gray: #eaeaea;
$border-line: #c0c0c0;
$table-month-text: #606F89;
$table-border-color: #E0E0E0;

// transparent color
$transparent: transparent;

// primary colors
$moderate-blue: #223189;
$strong-lime-green: #2ab930;

// secondary alerts colors

// red shades
$moderate-red: #be4e47;
$strong-red: #d61d11;
$soft-red: #ee6e72;
$pale-red: #ffd3d3;
$light-pale-red: #ffdee5;
$smooth-red: #ed5c5c;
$strong-orange: #bf710f;
$occupancy-changing: #910b08;

// blue shades
$violet-blue: #181059;
$soft-blue: #8ab9f6;
$light-soft-blue: #b9c3ff;
$very-soft-blue: #c5dcfa;
$bright-blue: #1200bc;
$strong-blue: #0f56b3;
$slight-moderate-blue: #4b41a9;
$light-blue: #8073ff;
$pale-blue: #d3e2ff;
$very-pale-blue: #c6d9ff;
$vivid-blue: #1672ec;
$sidebar-menu-hover-color: rgba(197, 220, 250, 0.6);
$manage-drop-down-hotel-name: #e2edfc;
$slider-header-bg: #F2F4FF;

// button-color
$primary-btn-color: #3d4ac0;

// green shades
$lime-green: #2ab930;
$grayish-lime-green: #e2f8e3;
$dark-green: #155d18;
$light-green: #c5f2c7;
$notification-color: #c5f2c7;
$prompt-hover: #0e3b10;

// secondary activity colors
$bright-yellow: #f4d146;
$very-light-blue: #7279ff;
$soft-orange: #ea9549;
$light-orange: #ffcc9e;
$cyan-lime-green: #6dda98;
$booked_inventory: #FFE3BA;

// primary gradient
$primary-gradient: linear-gradient(150.03deg, #24198a -1.74%, #73a3ff 108.95%);
$header-box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18),
0px 4px 8px rgba(97, 97, 97, 0.18);
$container-box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18),
0px 4px 8px rgba(97, 97, 97, 0.18);

// standard border radius
$border-radius: 1rem;

// border-radius
$border-radius2px: 2px;
$border-radius4px: 4px;
$border-radius5px: 5px;
$border-radius6px: 6px;
$border-radius8px: 8px;
$border-radius10px: 10px;
$border-radius12px: 12px;
$border-radius50px: 50px;
$border-radius99px: 99px;
$border-radius50per: 50%;

// font-size
$font-size80: 80%;
$font-size85: 85%;
$font-size90: 90%;
$font-size95: 95%;
$font-size100: 100%;

// font-weight
$font-weight300: 300;
$font-weight400: 400;
$font-weight500: 500;
$font-weight600: 600;
$font-weight700: 700;
$font-weight800: 800;

// standard margin bottom
$standard-margin-bottom: 1rem;

// Override bootstrap style variables
$component-active-bg: $moderate-blue !default;

// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
// Required
// @import "../../node_modules/bootstrap/scss/bootstrap";

// Custom.scss
// Option B: Include parts of Bootstrap (Decrease the bundle size after sass compilation)

// Include functions first (so you can manipulate colors, SVGs, calc, etc)
// Required
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

// Required SSCC where the variable is getting overridden
@import "../../../../node_modules/bootstrap/scss/forms/form-check";