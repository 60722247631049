.default_section_image_card_container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .blog_section_card_container {
        display: flex;
        flex-direction: column;
        // min-height: 250px;
        width: calc(100%/4.8);
        height: 200px;
        border: 1px solid $border-line;
        border-radius: 12px;
        position: relative;

        .image__container {
            height: 100px;

            img {
                height: 100px;
                width: 250px;
                object-fit: cover;
                border-radius: 12px 12px 0px 0px
            }


        }

        .blog_title {
            @include normal-text();
            padding: 0px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 30px;
        }

        .blog_description {
            @include small-text();
            padding: 0px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 70px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            transition: background 0.5s ease;
            border-radius: $border-radius8px;

            .button__container {
                @include center-div-flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                gap: 0.8rem;

                .delete-button {
                    @include center-div-flex;
                    opacity: 0;
                    transition: opacity 0.35s ease;

                    .bi-trash {
                        font-size: 1.3rem;
                        font-weight: $font-weight500;
                        color: $strong-red;
                    }
                }

                .edit_button {
                    @include center-div-flex;

                    opacity: 0;
                    transition: opacity 0.35s ease;

                    .bi-pencil-square {
                        font-size: 1.3rem;
                        font-weight: $font-weight500;
                        color: $moderate-blue;
                    }
                }
            }
        }

        &:hover .overlay {
            display: block;
            background: rgba(0, 0, 0, 0.3);
            border-radius: $border-radius8px;

            &:hover .delete-button {
                @include center-div-flex;
                opacity: 1;
                width: 40px;
                height: 40px;
                opacity: 1;
                text-align: center;
                padding-top: 0.2rem;
                color: $moderate-blue;
                background-color: $white-color;
                border-radius: $border-radius50per;
                transition: opacity 0.35s ease;
                cursor: pointer;
            }

            &:hover .edit_button {
                @include center-div-flex;
                opacity: 1;
                width: 40px;
                height: 40px;
                text-align: center;
                margin-right: 1rem;
                padding-top: 0.2rem;
                color: $moderate-blue;
                background-color: $white-color;
                border-radius: $border-radius50per;
                transition: opacity 0.35s ease;
                cursor: pointer;
                z-index: 11;
            }
        }
    }
}