.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: $manrope-font;
  line-height: 1.137em;
}

.css-q8hpuo-MuiFormControl-root {
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    margin-top: -0.1875rem;
  }
}

// for input feild
.css-q8hpuo-MuiFormControl-root {
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root,
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 13px 14px !important;
  }
}

// for select feild
.MuiOutlinedInput-root {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $moderate-blue !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $moderate-blue !important;
}

.inputfieldstyle {
  min-width: 100% !important;
  margin-bottom: 1.5rem !important;
}
.Mui-disabled {
  cursor: no-drop;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  cursor: no-drop !important;
}
