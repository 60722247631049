.card__footer {
  padding: 0.5rem;
  background-color: $white-color;
  border-radius: 0 0 $border-radius12px $border-radius12px;
  .package__hotel__name {
    font-weight: 700;
  }
}
//blog css
.blog__button {
  display: flex;
  align-items: center;
  .button-position {
    margin-left: auto;
  }
}
