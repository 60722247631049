.main-content-card {
  margin: auto;
  margin-top: 1.5rem;
  background-color: #ffffff;
  height: 63.563rem;
  width: 76rem;
  border-radius: 0.5rem;
  border: 1px solid #c0c0c0;
  margin-bottom: 1.5rem;
  .main-content-form {
    display: flex;
    flex-direction: column;
    .main-content-row {
      margin-top: 1.5rem;
      // margin-left: 0.3rem !important;
      display: flex;
      flex-direction: row;
      // border:1px solid black;
      .main-content-col {
        display: flex;
        flex-direction: column;
        // border:1px solid black;
        .form-label {
          color: #414141;
          font-size: 1rem;
          font-family: $manrope-font;
        }
        .log-drop {
          border: 1px solid #c0c0c0;
          border-radius: 0.5rem;
          height: 15.875rem;
        }
        .alt-text-area {
          border: 1px solid #a1a1a1;
          height: 2.86rem;
          border-radius: 0.5rem;
        }
        .div__amenities__container {
          border: 1px solid #a1a1a1;
          margin-top: 1.5rem;
          border-radius: 0.5rem;
        }
        .script-textarea {
          border: 1px solid #c0c0c0;
          border-radius: 0.5rem;
          height: 15.875rem;
          // width: 33.063rem;
        }
      }
    }
  }
}

// New Styles
.identity-content {
  margin: 1.5rem 0rem;

  .color-selector-wrapper {
    display: flex;
    gap: 1.5rem;
    .label {
      @include normal-text();
      margin-bottom: 0.8rem;
    }
  }
  .font-alt-text-wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    .theme-font {
      width: 30%;
      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
    }
    .alt-text {
      width: 70%;
      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
    }
  }
  .logo-favicon-wrapper {
    display: flex;
    gap: 1rem;
    .logo-section {
      width: 50%;
      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
    }
    .favicon-section {
      width: 50%;
      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
    }
  }
  .analytics-schema-wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    .analytics {
      width: 50%;

      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
      .script-textarea {
        min-height: 15rem;
      }
    }
    .schema {
      width: 50%;
      .label {
        @include normal-text();
        margin-bottom: 0.8rem;
      }
      .script-textarea {
        min-height: 15rem;
      }
    }
  }
  .save-btn {
    width: 10%;
    margin-top: 1rem;
  }
}
