.icon_wrapper {}

.someTitle {
    @include normal-text();
}

.email__section__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start !important;

    .email_button_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;


    }

    .email__addbtn {
        width: 35px;
        height: 35px;
        text-align: center;
        padding-top: 0.2rem;
        margin-left: 1rem;
        margin-bottom: 1.2rem;
        background-color: #223189;
        border-radius: 50%;
        color: #fff;

        &:hover {
            cursor: pointer;
        }


    }


}