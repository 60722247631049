.search__booking__wrapper {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: $border-radius8px;
  background-color: $white-color;
  border: 1px solid #e0e0e0;
  margin-bottom: 2rem;
  i {
    text-align: center;
    &::before {
      width: 30px;
    }
  }
}

.select__section__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.89rem 1rem;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;
  margin-bottom: 1rem;

  .select__section__content {
    @include center-div-flex;
    height: 100%;
    color: $dark-black;
    font-weight: $font-weight700;
  }

  .success__checkmark {
    @include center-div-flex();
    width: 20px;
    height: 20px;
    background-color: $strong-lime-green;
    border-radius: 50%;
    color: #ffffff;
    margin-left: 10px;
    left: 30px;
  }

  .select__section__checkbox {
    .form-check {
      input {
        &[type="checkbox"] {
          width: 23px;
          height: 23px;
        }
        cursor: pointer;
      }
    }

    .input__position {
      margin-left: auto;
    }

    .custom-size[type="checkbox"] {
      width: 24px;
      height: 24px;
    }
  }
}
