.option_selection_card_wrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 10px;

    .selection_image_wrapper {
        width: calc(100%/5.3);
        height: 200px;
        display: flex;
        flex-direction: column;
        border: 1px solid $border-line;
        border-radius: $border-radius12px;
        // flex: 0 0 19%;

        .image__container {
            background-color: #C5DCFA;
            height: 180px;
            @include center-div-flex();
            border-top-left-radius: $border-radius12px;
            border-top-right-radius: $border-radius12px;

            .bi-image {
                font-size: 40px;
                color: $moderate-blue;
            }


        }

        .hotel__name {
            background-color: $white-color;
            padding: 10px;
            @include normal-text();
            border-bottom-left-radius: $border-radius12px;
            border-bottom-right-radius: $border-radius12px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}