.card{
    background-color: #FFF;
    border:1px solid #C0C0C0;
    border-radius: 0.5rem;
    width:100%;
    // padding:2rem;
}
.image-drop-card-width{
    margin: 2rem;
    margin-bottom: 0;
}