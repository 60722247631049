.dropzone {
  border: 1px solid #c0c0c0;
  // border-radius: 0.5rem;
  height: 15.875rem;
  justify-content: center;
  display: flex;
  align-items: center;
  // width:33.063rem !important;
}
.slider__dropzone__height {
  min-height: 200px;
}
.description__slider {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
 
}
.div_image_drop {
  // @include center-div-flex();
  // min-width: 632px;
  width: 100%;
  height: 260px;
  background: #ffffff;
  // margin-top: 1.5rem;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;
  flex-wrap: wrap;
  .big-image-area {
    display: flex;
    .image-drop-area-big {
      position: relative;
      // @include center-div-flex();
      width: 232px;
      min-width: 232px;
      height: 232px;
      border: 1px dashed #c0c0c0;
      border-radius: $border-radius12px;
      margin: 0.938rem;
      .property__images {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius12px;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: background 0.5s ease;
        border-radius: $border-radius12px;
      }
      &:hover .overlay {
        display: block;
        background: rgba(0, 0, 0, 0.3);
        border-radius: $border-radius12px;
      }
      .remove-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        opacity: 0;
        transition: opacity 0.35s ease;
      }
      &:hover .remove-button {
        opacity: 1;
        color: $white-text;
        cursor: pointer;
      }
      .bi-x-square {
        font-size: 3.5rem;
      }
    }
    .small-image-area {
      display: flex;
      flex-wrap: wrap;
      margin: 0.313rem 0rem;
      .image-drop-area-small {
        @include center-div-flex();
        position: relative;
        width: 108px;
        height: 108px;
        border: 1px dashed #c0c0c0;
        border-radius: $border-radius12px;
        margin: 0.438rem 0.938rem 0.438rem 0rem;

        .property__images__small {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius12px;
        }
        .no_image-icon {
          color: $bold-gray;
          cursor: pointer;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          transition: background 0.5s ease;
          border-radius: $border-radius12px;
        }
        &:hover .overlay {
          display: block;
          background: rgba(0, 0, 0, 0.3);
          border-radius: $border-radius12px;
        }
        .remove-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto !important;
          opacity: 0;
          transition: opacity 0.35s ease;
        }
        &:hover .remove-button {
          opacity: 1;
          color: $white-text;
          cursor: pointer;
        }
        .bi-x-square {
          font-size: 1.5rem;
        }
      }
    }
  }
}
