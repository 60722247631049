.menu-card-wrapper {
  margin-top: 1rem;
  padding: 0;
  .menu-sec-wrapper {
    display: flex;
    flex-direction: row;
    // --bs-gutter-x: 0 !important;
    .menu-leftsec {
      width: 20%;
      background-color: $pale-blue;
      border-top-left-radius: $border-radius8px;
      border-bottom-left-radius: $border-radius8px;
      .menu-left-nav,
      .inactive {
        padding: 10px;
        @include normal-text();
        &:hover {
          background-color: $soft-blue;
          border-top-left-radius: $border-radius8px;
          cursor: move;
        }
      }
      .active {
        background-color: $soft-blue;
        border-top-left-radius: $border-radius8px;
        cursor: move;
      }
    }
    .menu-rightsec {
      width: 80%;
      padding: 15px;
      border-top-right-radius: $border-radius8px;
      border-bottom-right-radius: $border-radius8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .menu-select-page-label {
        @include normal-text();
      }
      .menu-dropdown-wrapper {
        width: 45%;
      }
      .menu-link-wrapper {
        width: 45%;
      }
      .menu-btn-warpper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-top: 15px;

        .update-btn {
          width: 15%;
        }
      }
    }
  }
}
