.edit-facebook-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  .edit-title-text-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .edit-promo-alt-label {
    margin: 10px 0px;
    @include normal-text();
  }

  .edit__dropzone__container__facebook {
    margin: 0 0 0 0;
  }

  .edit-promo-btn-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
  }
  .edit-newsletter-text-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
