.no-data-label-container {
  @include center-div-flex();
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  .bi-x-circle {
    font-size: 20px;
  }
  .no-data-label {
    @include normal-text();
  }
}
