.section__heading {

  // width: 100%;
  a {
    cursor: default;
  }

  @include center-div-flex();
  justify-content: flex-start;

  .breadcrumbs__labels {
    @include normal-text();
    color: $moderate-blue;

    &:hover {
      cursor: pointer;
    }
  }

  .breadcrumbs__labels_second {
    @include normal-text();
  }

  .breadcrumbs__arrows {
    padding: 0 0.3rem;
  }
}