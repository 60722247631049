.wb-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .header_hotel_name {
    @include normal-text();
    color: $moderate-blue;
  }
  .wb-header-preview {
    // background-color: #fff;
    // border: 1px solid #6876B1;
    // margin-right: 20px;
    // color: #223186;
    // font-weight: bold;
    // font-size: 1rem;
    // &:hover{
    //     color: #fff;
    //     height: 100%;
    //     border:  1px solid #fff;
    // }
    @include primary-btn(
      $moderate-blue,
      $white-color,
      $moderate-blue,
      $white-color
    );
    justify-content: space-between;
    min-width: 136px;
    font-size: $font-size95;
    font-weight: $font-weight700;
    padding: 0.44rem 1rem;
    margin: 0rem 0.4rem;
    background-color: $white-color;
    border: 1px solid $moderate-blue;
    border-radius: $border-radius8px;
    transition: background-color 300ms;
    cursor: pointer;
  }
  .notification__wrapper {
    width: 40px;
    height: 40px;
    @include center-div-flex();
    border-radius: $border-radius50per;
    background-color: $notification-color;
    margin: 0rem 0.4rem;

    .notification--icon {
      color: $dark-green;
      font-size: 1.4rem;
      font-weight: $font-weight800;
    }
  }
}
