.near__place__map__container {
  height: 254px;
  margin: 1.5rem;
  border-radius: $border-radius8px;
  border: 1px solid $border-line;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius8px;
  }
}
//Event Css
.wb__page__input__field {
  padding: 0.5rem;
}
