.banner-img-wrapper {
  display: flex;
  flex-wrap: wrap;
  border-radius: $border-radius8px;
  border: 1px solid $border-line;
  position: relative;
  background-color: $very-light-gray;
  height: 20rem;

  .banner-img {
    width: 100%;
    height: 100%;
    border-radius: $border-radius8px;
  }

  .edit-btn-banner {
    @extend %small-btn;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .banner-titel {
    min-width: 40%;
    position: absolute;
    margin-left: 1.5rem;
    background-color: $transparent;
    z-index: 1;
    bottom: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 4rem;
    width: 10rem;

    .b-titel-text {
      @include normal-text();
      //   font-family: $font-weight800;
      //   font-size: $font-size100;
      color: $dark-black;

      .no-banner-titel {
        @include normal-text();
      }
    }

    .edit-btn-banner-titel {
      @extend %small-btn;
    }
  }
}

.about-add-sec-card {
  @include center-div-flex();

  .section-btn {
    width: 25%;
  }
}

// About Banner Edit Css
.edit-abt-image {
  height: 20rem;
  --bs-gutter-x: 0rem;

  .edit-abt-left {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    height: 100%;

    .edit-abt-inpt {
      width: 95%;
      // align-self: center;
      margin-left: 1rem;
    }
  }

  .edit-abt-right {
    // border:  1px solid red;
    height: 100%;

    .banner-dropzone {
      margin-top: 1rem;
      height: 18rem !important;
      margin-right: 1rem;
      min-height: 0;

      .banner-image {
        min-height: 0;
        height: 18rem !important;
      }
    }
  }
}

.edit-banner-card-wrapper {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .edit-banner-left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    // gap: 1.5rem;
    justify-content: space-between;
  }

  .edit-banner-right-side {
    flex: 1;

    .banner-dropzone {
      min-height: 280px;
    }
  }
}