.edit__dropzone__container {
  margin: 0rem 0rem 0 0rem;
}

.default__setion__image {
  .image__container {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background 0.5s ease;
      border-radius: $border-radius8px;

      .button__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;

        .edit-button {
          @include center-div-flex;
          opacity: 0;
          transition: opacity 0.35s ease;

          .bi-pencil-square {
            font-size: 1.3rem;
            font-weight: $font-weight500;
            color: $moderate-blue;
          }
        }
      }
    }

    .overlay:hover {
      display: block;
      background: rgba(0, 0, 0, 0.3);
      border-radius: $border-radius8px;

      .edit-button {
        @include center-div-flex;
        opacity: 1;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 0.2rem;
        color: $moderate-blue;
        background-color: $white-color;
        border-radius: $border-radius50per;
        transition: opacity 0.35s ease;
        cursor: pointer;
        z-index: 11;
      }
    }
  }
}

.display__none {
  display: none;

  .reorder__cursor {
    @include center-div-flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: -10px;
    left: -10px;
    background-color: $white-color;
    color: $soft-orange;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: -10px;
    left: -10px;
    z-index: 11;
  }
}

// Edit Hotel Rooms Styles
.default_section_image_card_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  // height: 255px;
  gap: 10px;


  .grabbable {
    width: calc(100%/5.2) !important;
    height: 255px;
    position: relative;

    .reorder__cursor {
      position: absolute;
      top: -12px;
      left: -12px;
      z-index: 1;
      background-color: white;
      width: 30px;
      height: 30px;
      @include center-div-flex();
      border-radius: 50%;
      color: $soft-orange;
      visibility: hidden;
    }

    .room_type_card_reorder_wrapper {
      display: flex;
      flex-flow: column wrap;
      border-radius: $border-radius12px;
      border: 1px solid $border-line;

      .image__container {
        .room_type_image {
          width: 100%;
          min-height: 200px;
          border-top-left-radius: $border-radius12px ;
          border-top-right-radius: $border-radius12px;
        }
      }

      .name-update-wrapper {
        padding: 5px;
        background-color: $white-color;
        border-bottom-left-radius: $border-radius12px;
        border-bottom-right-radius: $border-radius12px;

        .hotel__room__name {
          @include normal-text();
        }

        .last-updated {
          @include sub-text();
          font-weight: 300;
        }
      }
    }

    &:hover {
      .reorder__cursor {
        visibility: visible;
      }

      .room_type_card_reorder_wrapper {
        border: 1px solid $soft-orange;
      }
    }
  }



  .default__setion__image {
    flex-basis: calc(100%/5.2);
    // flex-basis: 25%;
    margin-bottom: 0 !important;
    margin-right: 0 !important;

    .image__container {
      display: flex;
      flex-direction: column;
      // width: 98%;
      width: 100%;
      border: 1px solid $border-line;
      border-radius: $border-radius12px;
      position: relative;

      .reorder__cursor {
        position: absolute;
        z-index: 1;
        color: $soft-orange;
        top: -10px;
        left: -10px;
        background-color: $white-color;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        opacity: 0;
      }

      .hotel__room__image {
        min-height: 195px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: $border-radius12px $border-radius12px 0 0;
        }
      }

      .name-update-wrapper {
        background-color: $white-color;
        border-radius: 0 0 $border-radius12px $border-radius12px;
        padding: 10px;

        .hotel__room__name {
          @include normal-text();
        }

        .last-updated {
          @include small-text();
        }
      }

      &:hover {
        cursor: pointer;
        border-radius: $border-radius12px;
        border: 1px solid $soft-orange !important;

        .reorder__cursor {
          position: absolute;
          z-index: 1;
          color: $soft-orange;
          top: -10px;
          left: -10px;
          background-color: $white-color;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          text-align: center;
          opacity: 1;
        }
      }
    }
  }
}

//
.top__button__container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.description {
  margin: 0 0.8rem 0 0.2rem;
}

.room_reoder_update_button {
  display: flex;
  justify-content: end;
  margin: 15px 0px;

  .updatee-btn {
    width: 15%;
  }

}

.icon_container {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .icon_wrapper {
    @include normal-text();
    padding: 5px;
    border-radius: 8px;
    border: 1px solid $border-line;

    &:hover {
      border: 1px solid $moderate-blue;
      background-color: #ddd;
      cursor: pointer;
    }
  }

  .selectedIcon {
    border: 1px solid $moderate-blue;
    background-color: #ddd;
  }

  h5 {
    text-align: center;
    @include sub-text()
  }
}