.promotion_section_hotel_card_container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .default__setion__image {
        width: calc(100%/5.3);
        height: 200px;
        display: flex;
        flex-direction: column;
        border: 1px solid $border-line;
        border-radius: $border-radius12px;

        .image__container {
            background-color: #C5DCFA;
            height: 180px;
            @include center-div-flex();
            border-top-left-radius: $border-radius12px;
            border-top-right-radius: $border-radius12px;
            position: relative;

            .bi-file-earmark-richtext {
                font-size: 40px;
                color: $moderate-blue;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0);
                transition: background 0.5s ease;
                border-radius: $border-radius8px;

                .button__container {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: auto;

                    .edit-button {
                        @include center-div-flex;
                        opacity: 0;
                        transition: opacity 0.35s ease;

                        .bi-pencil-square {
                            font-size: 1.3rem;
                            font-weight: $font-weight500;
                            color: $moderate-blue;
                        }
                    }
                }
            }

            .overlay:hover {
                display: block;
                background: rgba(0, 0, 0, 0.3);
                border-radius: $border-radius8px;

                .edit-button {
                    @include center-div-flex;
                    opacity: 1;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    padding-top: 0.2rem;
                    color: $moderate-blue;
                    background-color: $white-color;
                    border-radius: $border-radius50per;
                    transition: opacity 0.35s ease;
                    cursor: pointer;
                    z-index: 11;
                }
            }


        }
    }
}

.promo_sec_color_picker {
    width: 100%;
    margin-bottom: 20px;
}