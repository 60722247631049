.booking__benifts__card {
  margin: 0.5rem;
  padding: 1rem 1.5rem;
  box-shadow: $container-box-shadow;
  width: fit-content;
  border-radius: $border-radius8px;
  font-weight: $font-weight600;
}

.small__card__container {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem;
  margin-bottom: 0;
}

.benifits__auto__width__card__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.cfooter-text-container {
  margin: 1rem 0rem;
}

.custom-width-color-picker {
  width: 100%;
  margin-top: 0.3rem;
}

// .card__padding__custom__footer {
//   margin-top: 1rem;
// }

.benifits__auto__width__card__container {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .grabbable {
    .selected_benifits_wrapper {
      padding: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;
      align-items: center;
      background-color: $white-text;
      border-radius: 8px;
      box-shadow: $container-box-shadow;

      .left_icon_wrapper {
        color: $moderate-blue;
      }

      .benifits_name {
        @include normal-text();
      }

      .benifits_button_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;

        .button {
          @extend %small-btn;
          box-shadow: $container-box-shadow;

          .bi-pencil-square {
            color: $moderate-blue;
          }

          .bi-trash {
            color: $soft-red;
          }
        }
      }
    }
  }
}