.wrapper {
  background-color: $white-color;
  border: 1px solid $border-line;
  height: 250px;
  width: 99%;
  border-radius: $border-radius10px;
  // margin-left: 1rem;
  // margin-right: 1rem;
  margin-bottom: 2rem;
  display: inline-flex;
  flex-direction: column;
  // position: relative;
  .card-sec-top {
    @include center-div-flex();
    background-color: $very-soft-blue;
    height: 190px;
    font-size: 4rem;
    color: $moderate-blue;
    border-top-left-radius: $border-radius10px;
    border-top-right-radius: $border-radius10px;
    position: relative;
    .active {
      position: absolute;
      top: 1rem;
      left: 1rem;
      height: 1.75rem;
      width: 4.625rem;
      background-color: #c5f2c7;
      font-size: 0.875rem;
      color: #155d18;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 0.75rem;
    }
    .inactive {
      position: absolute;
      top: 1rem;
      left: 1rem;
      height: 1.75rem;
      width: 4.625rem;
      background-color: #e0e0e0;
      font-size: 0.875rem;
      color: #a9a9a9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 0.75rem;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background 0.5s ease;
      border-radius: $border-radius8px $border-radius8px 0 0;
      .button__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.35s ease;
          .bi-icon {
            font-size: 1rem;
            font-weight: $font-weight500;
            color: $moderate-blue;
            align-self: center;
          }
        }
      }
    }
    &:hover {
      .overlay {
        display: block;
        background: rgba(0, 0, 0, 0.3);
        border-radius: $border-radius8px $border-radius8px 0 0;
        &:hover {
          .button__container {
            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 1;
              width: 40px;
              height: 40px;
              opacity: 1;
              text-align: center;
              padding-top: 0.2rem;
              color: $moderate-blue;
              background-color: $white-color;
              border-radius: $border-radius50per;
              transition: opacity 0.35s ease;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .page-title-update-wrapper {
    padding: 10px;
    .page-titel {
      @include normal-text();
      // font-family: $manrope-font;
      // font-weight: bold;
    }
    .page-update {
      @include sub-text();

      font-weight: $font-weight400;
    }
  }
}
