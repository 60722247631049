.edit-promo-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .edit-promo-alt-label {
    // margin: 10px 0px;
    margin: 1rem 0rem;
    @include normal-text();
  }

  .edit-promo-btn-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
  }

  .edit-newsletter-text-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

.edit-contact-btn-container {
  display: flex;
  // padding: 1.5rem 2.5rem;
  margin-top: 1rem;

  .cancel__btn {
    margin-right: 2rem;
  }

  .savebtn {
    margin-top: 0;
  }
}
