.testimonial__card__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  .testimonial__card__wrapper {
    width: 270px;
    height: 250px;
    padding: 1rem;
    position: relative;
    margin-bottom: 2rem;
    margin-right: 2rem;
    background-color: $white-color;
    border: 1px solid $border-line;
    border-radius: $border-radius12px;
    .testimonial__image {
      width: 60px;
      height: 60px;
      border: 1px solid $border-line;
      border-radius: $border-radius50per;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius50per;
      }
    }
    .testi__label {
      margin-bottom: 0.5rem;
      font-weight: $font-weight700;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background 0.5s ease;
      border-radius: $border-radius8px;
      .button__container {
        @include center-div-flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        gap: 0.8rem;
        .delete-button {
          @include center-div-flex;
          opacity: 0;
          transition: opacity 0.35s ease;
          .bi-trash {
            font-size: 1.3rem;
            font-weight: $font-weight500;
            color: $strong-red;
          }
        }
        .edit_button {
          @include center-div-flex;

          opacity: 0;
          transition: opacity 0.35s ease;
          .bi-pencil-square {
            font-size: 1.3rem;
            font-weight: $font-weight500;
            color: $moderate-blue;
          }
        }
      }
    }
    &:hover .overlay {
      display: block;
      background: rgba(0, 0, 0, 0.3);
      border-radius: $border-radius8px;
      &:hover .delete-button {
        @include center-div-flex;
        opacity: 1;
        width: 40px;
        height: 40px;
        opacity: 1;
        text-align: center;
        padding-top: 0.2rem;
        color: $moderate-blue;
        background-color: $white-color;
        border-radius: $border-radius50per;
        transition: opacity 0.35s ease;
        cursor: pointer;
      }
      &:hover .edit_button {
        @include center-div-flex;
        opacity: 1;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 0.2rem;
        color: $moderate-blue;
        background-color: $white-color;
        border-radius: $border-radius50per;
        transition: opacity 0.35s ease;
        cursor: pointer;
        z-index: 11;
      }
    }
  }
}
