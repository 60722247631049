.image__drag__drop__container {
  @include center-div-flex();
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 344px;
  background-color: $white-color;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;

  .image_drag_area {
    @include center-div-flex();
    flex-direction: column;
    width: 10.5rem;
    height: 84px;
    border: 1px dashed $border-line;
    border-radius: $border-radius8px;
    @include sub-text();
    color: $bold-gray;
    cursor: pointer;

    .text-area {
      padding: 0px 1.563rem;
      width: 100%;
      text-align: center;
    }
  }

  .image-preview-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 1rem;
    justify-content: center;
    width: 100%;

    .image-preview-wrapper {
      position: relative;
      padding: 8px;
      background-color: $white-color;
      border-radius: $border-radius8px;
      border: 1px solid $border-line;

      .image-preview-size {
        height: 120px;
        width: 150px;
      }

      .circular-progress-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .MuiBox-root {
          .MuiCircularProgress-root {
            color: $white-color;
          }

          .MuiTypography-root {
            color: $white-color;
            font-weight: 500;
          }
        }
      }

      .overlay-effect {
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: background 0.5s ease;
        border-radius: $border-radius8px;

        .edit-delet-btn-warpper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .img-delet-btn {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            opacity: 0;
            transition: opacity 0.35s ease;
            @include center-div-flex();
            background-color: $white-color;
            border-radius: $border-radius50per;

            .img-delete-icon {
              // opacity: 0;
              font-size: 1.3rem;
              font-weight: $font-weight500;
              color: $strong-red;
            }

            .img-edit-icon {
              // opacity: 0;
              font-size: 1.3rem;
              font-weight: $font-weight500;
              color: $moderate-blue;
            }
          }
        }

        &:hover {
          // display: block;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.3);
          border-radius: $border-radius8px;

          .img-delet-btn {
            @include center-div-flex;
            opacity: 1;
            width: 40px;
            height: 40px;
            text-align: center;
            padding-top: 0.2rem;
            color: $moderate-blue;
            background-color: $white-color;
            border-radius: $border-radius50per;
            transition: opacity 0.35s ease;
            cursor: pointer;
            z-index: 11;

            .img-delete-icon {
              opacity: 1;
              font-size: 1.3rem;
              font-weight: $font-weight500;
              color: $strong-red;
            }

            .img-edit-icon {
              opacity: 1;
              font-size: 1.3rem;
              font-weight: $font-weight500;
              color: $moderate-blue;
            }
          }
        }
      }
    }

    .image-preview-wrapper:hover {
      .overlay-effect {
        display: flex;
      }
    }
  }

  // .image-display-area{
  //   border: 1px solid red;
  //   height: 12rem;
  //   background-color: red;
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   .image-thumb{
  //     background-color: white;
  //     display: inline-flex;
  //     border-radius: 0.1rem;
  //     border: 1px solid #eaeaea;
  //     width: 100;
  //     height: 100;
  //     padding: 4px;
  //     box-sizing: border-box;
  //     .image-thumbInner{
  //       display: flex;
  //        min-width: 0;
  //       overflow: hidden;
  //       .img{
  //         display: block;
  //         width: auto;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }
}

.image__drop__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 344px;
  padding: 3rem 4rem;
  background-color: $white-color;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;

  .image_drop_container-each {
    width: 200px;
    height: 180px;
    min-height: 180px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-height: 0;
      object-fit: cover;
      border-radius: $border-radius8px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background 0.5s ease;
      border-radius: $border-radius8px;

      .button__container {
        @include center-div-flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;

        .delete-button {
          @include center-div-flex;
          opacity: 0;
          transition: opacity 0.35s ease;

          .bi-trash {
            font-size: 1.3rem;
            font-weight: $font-weight500;
            color: $strong-red;
          }
        }

        .edit_button {
          @include center-div-flex;
          opacity: 0;
          transition: opacity 0.35s ease;

          .bi-pencil-square {
            font-size: 1.3rem;
            font-weight: $font-weight500;
            color: $moderate-blue;
          }
        }
      }
    }

    &:hover .overlay {
      display: block;
      background: rgba(0, 0, 0, 0.3);
      border-radius: $border-radius8px;

      &:hover .delete-button {
        @include center-div-flex;
        opacity: 1;
        width: 40px;
        height: 40px;
        opacity: 1;
        text-align: center;
        padding-top: 0.2rem;
        color: $moderate-blue;
        background-color: $white-color;
        border-radius: $border-radius50per;
        transition: opacity 0.35s ease;
        cursor: pointer;
      }

      &:hover .edit_button {
        @include center-div-flex;
        opacity: 1;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 0.2rem;
        color: $moderate-blue;
        background-color: $white-color;
        border-radius: $border-radius50per;
        transition: opacity 0.35s ease;
        cursor: pointer;
        z-index: 11;
      }
    }
  }

  .drag__drop__button {
    margin-top: 3.5rem;
    @include center-div-flex();
    width: 100%;
  }
}