// standard heading
@mixin main-text {
    color: $dark-gray;
    font-family: $manrope-font;
    font-size: 1.5rem;
    line-height: 132%;
    letter-spacing: 0%;
    font-weight: $font-weight800;
}

@mixin extra-bold {
    color: $dark-gray;
    font-family: $manrope-font;
    font-size: 1.125rem;
    line-height: 132%;
    letter-spacing: 0%;
    font-weight: $font-weight800;
}

@mixin normal-text {
    color: $dark-gray;
    font-family: $manrope-font;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.5%;
    font-weight: $font-weight700;
}

@mixin sub-text  {
    color: $dark-gray;
    font-family: $manrope-font;
    font-size: 0.875rem;
    line-height: 142%;
    letter-spacing: 0.5%;
    font-weight: $font-weight700;
}

@mixin small-text {
    color: $dark-gray;
    font-family: $manrope-font;
    font-size: 0.75rem;
    line-height: 130%;
    letter-spacing: 3%;
    font-weight: $font-weight400;
}