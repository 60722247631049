.edit-promo-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .edit-aboutus-text-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}

.select__hotel__label {
  @include normal-text();
}

.default_section_image_card_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  .default__setion__image {
    // flex-basis: 25%;
    flex-basis: 22%;
    margin-bottom: 2rem;
    margin-right: 2rem;

    .image__container {
      display: flex;
      flex-direction: column;
      // width: 98%;
      width: 100%;
      border: 1px solid $border-line;
      border-radius: $border-radius12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: $border-radius12px $border-radius12px 0 0;
      }

      .image_container_icon_wrapper {
        @include center-div-flex();
        height: 180px;
        border-top-left-radius: $border-radius12px;
        border-top-right-radius: $border-radius12px;
      }

      .hotel__name {
        font-size: 18px;
        font-weight: 700;
        padding: 1rem 0.5rem;
        background-color: $white-color;
        border-radius: 0 0 $border-radius12px $border-radius12px;
      }
    }
  }
}

.sixtypx-height {
  height: 60px;
}

//
.about-hotel-slider-main-contant-warpper {
  .select-image-label {
    @include normal-text();
  }
}