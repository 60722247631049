// standard btn styles
.primary-btn {
  @include primary-btn(
    $white-text,
    $primary-btn-color,
    $moderate-blue,
    $white-text
  );
  @include center-div-flex();
  .icon-wrapper,
  .label-wrapper {
    display: inline-block;
    margin: 0 0.243rem;
  }
  .icon-wrapper {
    i {
      margin-top: unset;
    }
  }
  // .spinner-border-sm {
  //   border-width: 0.2rem;
  // }
}

.__next__btn__wrapper {
  display: flex;
  align-items: center;
  .__next__btn {
    @include primary-btn(
      $white-color,
      $primary-btn-color,
      $moderate-blue,
      $white-color
    );
    margin: 0 0.8rem;
    .icon--pos {
      position: relative;
      top: 0.15rem;
      margin-left: 0.5rem;
    }
  }
}
.__btn__wrapper {
  display: flex;
  align-items: center;
  .back__btn {
    min-width: 80px;
    max-width: 80px;
    margin-right: 1rem;
    padding: 0.687rem 1rem;
    // padding: 0.2rem 0;
    color: $moderate-blue;
    font-weight: $font-weight600;
    background-color: $white-color;
    box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
      0px 2px 4px rgba(15, 86, 179, 0.2);
    &::before {
      background-color: $white-color;
      color: $moderate-blue;
    }
  }
}
.skip-area {
  width: 100px;
  font-weight: $font-weight700;
  text-align: right;
  color: $moderate-blue;
  padding: 0.657rem 1rem;
  cursor: pointer;
}
.bck-btn {
  background-color: #fff;
  border: 1px solid #6876b1;
  // margin-right: 20px;
  color: #223186;
  font-weight: bold;
  font-size: 1rem;
}
.savebtn {
  width: 10%;
  height: 100%;
  margin-top: 1.5rem;
  border: 1px solid #6876b1;
}
.cancel__btn {
  min-width: 106.33px;
  max-width: 106.33px;
  // margin-top: 1.5rem;
  @include primary-btn($strong-red, $white-color, $pale-red, $strong-red);
  font-weight: $font-weight500;
  border: 1px solid $strong-red;
  &:hover {
    border-color: transparent;
  }
}

.fetch__btn {
  @include primary-btn(
    $moderate-blue,
    $white-color,
    $moderate-blue,
    $white-color
  );
  justify-content: space-between;
  font-size: 1rem;
  font-weight: $font-weight700;
  min-width: 136px;
  padding: 0.44rem 1rem;
  margin: 0rem 0.4rem;
  background-color: $white-color;
  border: 1px solid $moderate-blue;
  border-radius: $border-radius8px;
  transition: background-color 300ms;
  cursor: pointer;

  // @include normal-text();
  // background-color: $white-text;
  // color: $moderate-blue;
  // border: 1px solid $lighter-gray;
  // height: 42px;
  // &::before {
  //   // background-color: $white-color;
  //   // color: $moderate-blue;
  //   color: #fff;
  //   height: 100%;
  //   border: 1px solid #fff;
  // }
}

.inactiveBtn {
  @include primary-btn($white-text, $lighter-gray, $lighter-gray, $white-text);

  &:hover {
    cursor: no-drop;
  }

  .fetching {
    animation: rotation 2s infinite cubic-bezier(0.1, 0.64, 0.58, 1);
    @keyframes rotation {
      50% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
}
