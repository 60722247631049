.rect-sec-card-wrapper {
  // border: 1px solid black;
  margin: 15px 0px;
  padding: 10px 15px 10px 10px;
  background-color: $white-color;
  border-radius: $border-radius10px;
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $bold-gray;
  font-weight: $font-weight600;
  font-family: $manrope-font;
  box-shadow: 0px 1px 16px 2px rgba(97, 97, 97, 0.1),
    0px 16px 32px 2px rgba(97, 97, 97, 0.1);

  .left-sec {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .bi-grip-vertical {
      color: $moderate-blue;
      font-size: 1rem;
    }
  }
  .right-sec {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .button {
      @extend %small-btn;
      box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
        0px 2px 4px rgba(15, 86, 179, 0.2);
      .bi-trash {
        color: #e64467 !important;
      }
    }
  }
}
.custom-draggble-card {
  width: 100%;
}
.auto__width__card {
  @include center-div-flex();
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.8rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  width: fit-content;
  box-shadow: $container-box-shadow;
  .bi-grip-vertical,
  .bi-pencil-square {
    color: $moderate-blue;
  }
  .bi-trash {
    color: $soft-red;
  }
  .left_section {
    display: flex;
  }
  .right_section {
    display: flex;
    gap: 10px;
    margin-left: 1rem;
  }
  .button {
    @extend %small-btn;
    box-shadow: $container-box-shadow;
  }
  .sec-name {
    margin-left: 0.5rem;
    color: $bold-gray;
    font-weight: $font-weight600;
  }
}
